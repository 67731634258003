import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import passwordsApi from 'apis/passwordsApi'
import PATHS from 'constants/paths'
import useForm, { Validations } from 'hooks/useForm'
import usePromise from 'hooks/usePromise'
import { emailValidator, presenceValidator } from 'utils/validators'

const initialValues = {
  email: '',
}

const validations: Validations<typeof initialValues> = {
  email: [
    { validator: presenceValidator, message: 'Email is missing' },
    { validator: emailValidator, message: 'Email is not valid' },
  ],
}

const ForgotPassword = () => {
  const { textFieldProps, handleFormSubmit } = useForm(initialValues, {
    validations,
  })
  const [{ status, response, error }, loginAction] = usePromise(passwordsApi.generateResetPassword)

  const successMessage = response?.data?.message

  return (
    <Box maxWidth="500px" margin="1em auto" component={Paper}>
      <Button to={PATHS.forgotPassword} component={RouterLink}>
        <ChevronLeft sx={{ verticalAlign: 'middle' }} />
        Back
      </Button>
      <form onSubmit={handleFormSubmit(loginAction)}>
        <Stack padding={2} spacing={2}>
          <TextField label="Email" {...textFieldProps('email')} />
          <Button type="submit" variant="contained" disabled={status.isPending}>
            Reset password
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
          {!!successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Stack>
      </form>
    </Box>
  )
}

export default ForgotPassword
