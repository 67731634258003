import React from 'react'

import PromiseGate, { PromiseGateProps } from 'components/shared/PromiseGate'
import useAppConfig from 'hooks/useAppConfig'

export interface OrganizationalUnitGateProps extends Omit<PromiseGateProps, 'promise' | 'deps'> {
  types: string[]
}

const OrganizationalUnitGate = ({ types, ...props }: OrganizationalUnitGateProps) => {
  const { organizationalUnitType } = useAppConfig()

  return (
    <PromiseGate
      {...props}
      promise={() => Promise.resolve(types.includes(organizationalUnitType))}
      deps={types}
    />
  )
}

export default OrganizationalUnitGate
