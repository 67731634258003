import { Button, TextField } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import React from 'react'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'

import passwordsApi from 'apis/passwordsApi'
import PATHS from 'constants/paths'
import useForm, { Validations } from 'hooks/useForm'
import usePromise from 'hooks/usePromise'
import { presenceValidator } from 'utils/validators'

const initialValues = {
  password: '',
  passwordConfirmation: '',
  resetPasswordToken: '',
}

const validations: Validations<typeof initialValues> = {
  password: [{ validator: presenceValidator, message: 'Password is missing' }],
  passwordConfirmation: [
    { validator: presenceValidator, message: 'Password confirmation is missing' },
  ],
}

const PasswordReset = () => {
  const [searchParams] = useSearchParams()

  const { textFieldProps, handleFormSubmit } = useForm(
    {
      ...initialValues,
      resetPasswordToken: searchParams.get('reset_password_token'),
    },
    {
      validations,
    },
  )
  const [{ status, error }, action] = usePromise(passwordsApi.resetPassword)

  if (status.isResolved && !status.isError) {
    return (
      <Box maxWidth="500px" margin="1em auto" component={Paper}>
        <Alert severity="success">
          Password updated successfully. Please{' '}
          <Link to={PATHS.login} component={RouterLink}>
            login
          </Link>
          .
        </Alert>
      </Box>
    )
  }
  return (
    <Box maxWidth="500px" margin="1em auto" component={Paper}>
      <form onSubmit={handleFormSubmit(action)}>
        <Stack padding={2} spacing={2}>
          <TextField label="Password" type="password" {...textFieldProps('password')} />
          <TextField
            label="Password Confirmation"
            type="password"
            {...textFieldProps('passwordConfirmation')}
          />
          <Button type="submit" variant="contained" disabled={status.isPending}>
            Update password
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </form>
    </Box>
  )
}

export default PasswordReset
