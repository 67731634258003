import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'

import OrganizationalUnitGate from 'components/shared/OrganizationalUnitGate'
import Redirect from 'components/shared/Redirect'
import useAppConfig from 'hooks/useAppConfig'

import Form from './Form'

const Login = () => {
  const { user } = useAppConfig()

  if (user) return <Redirect to={user.homePath || '/'} replace />

  return (
    <OrganizationalUnitGate
      types={['ops', 'global']}
      fallback={
        <Box
          maxWidth="750px"
          margin="1em auto"
          component={Paper}
          sx={{ p: 2, textAlign: 'center' }}
        >
          <Typography variant="h4" gutterBottom>
            Looks like you’re not logged in yet.
          </Typography>
          <Typography variant="body1">
            Please log in through your member online portal to access this page.
          </Typography>
        </Box>
      }
    >
      <Form />
    </OrganizationalUnitGate>
  )
}

export default Login
